import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Workdata from './includes/Workdata';
 


export default function Services() {
  return ( 
    <>
    <div style={{height:'50px'}}></div>
    <Workdata/>
    </>
  )
}
