 
import Container from 'react-bootstrap/Container';

export default function Footer() {
  return (
    <Container fluid="md">
     
    <div className="footer-content">
    &#169; 2010 XTREME DESIGN 
                </div>
      
        </Container>
    
  )
}
